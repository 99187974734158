import { Category, Session, User } from "services-hooks/types";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppState } from "../store";
import { v4 } from "uuid";
import { getAccountUsersMePresets } from "services-hooks/services";
import { ActiveMedia } from "./player";

interface PreviouslyPlayed {
  dismissed: boolean;
  media: ActiveMedia | null;
}
export interface UserState {
  session: Session | null;
  favoritesLoading: boolean;
  deviceId: string;
  favorites: Category[];
  ip: string | null;
  previouslyPlaying: PreviouslyPlayed | null;
  lastAdCompletedDate: number | null;
  selectedDiscoverLanguage: {
    name: string;
    logo: string;
    permalink: string;
  } | null;
}

const initialState: UserState = {
  session: null,
  deviceId: v4(),
  favoritesLoading: false,
  favorites: [],
  ip: null,
  previouslyPlaying: null,
  lastAdCompletedDate: null,
  selectedDiscoverLanguage: null
};

const loadFavorites = createAsyncThunk("salons/fetch", async () => {
  try {
    const response = await getAccountUsersMePresets({});
    return response.data.body?.content || [];
  } catch (e) {
    return [];
  }
});

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setSession: (state, action: PayloadAction<Session | null>) => {
      state.session = action.payload;
    },
    setUser: (state, action: PayloadAction<User>) => {
      if (state.session) {
        state.session.user = action.payload;
      }
    },
    setIP: (state, action: PayloadAction<string | null>) => {
      state.ip = action.payload;
    },
    logout: (state) => {
      state.session = null;
      state.favorites = [];
      state.favoritesLoading = false;
      state.previouslyPlaying = null;
    },
    reset: () => initialState,
    setPreviouslyPlaying: (
      state,
      payload: PayloadAction<PreviouslyPlayed | null>
    ) => {
      state.previouslyPlaying = payload.payload;
    },
    setLastAdTimestamp: (state, payload: PayloadAction<number | null>) => {
      state.lastAdCompletedDate = payload.payload;
    },
    setSelectedDiscoverLanguage: (state, payload: PayloadAction<{
      name: string;
      logo: string;
      permalink: string;
    } | null>) => {
      state.selectedDiscoverLanguage = payload.payload;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(loadFavorites.pending, (state, action) => {
        state.favoritesLoading = true;
      })
      .addCase(loadFavorites.fulfilled, (state, action) => {
        state.favoritesLoading = false;
        state.favorites = action.payload;
      })
      .addCase(loadFavorites.rejected, (state, action) => {
        state.favoritesLoading = false;
      });
  },
});
export const userActions = { ...userSlice.actions, loadFavorites };
export const selectUserState = (state: AppState) => state.user;
export default userSlice.reducer;
