import { useTranslate } from "app/hooks/lang";

const useMetaDescription = () => {
  const t = useTranslate();

  return (text?: string) => (
    <meta name="description" content={text || t("seo_description_home")} />
  );
};

export default useMetaDescription;
