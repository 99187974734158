var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"b9fbfe9a49024c2d6a3bfe6c2e22b4fa3b2574c6"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;
const DISABLE_SENTRY = process.env.NEXT_PUBLIC_DISABLE_SENTRY === "true";

Sentry.init({
  dsn: SENTRY_DSN || 'https://bab95c1235e441a99c861a99d8f13bc0@o1054791.ingest.sentry.io/6106954',
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 0.05,
  sampleRate: 0.05,
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
  enabled: !DISABLE_SENTRY
});
