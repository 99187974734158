import { useTranslate } from "app/hooks/lang";
import clsx from "clsx";
import Link from "next/link";
import { MouseEvent } from "react";
import MemoLogo from "../icons/Logo";
import MemoLogoSmall from "../icons/LogoSmall";
import styles from "./footer.module.scss";

export const Footer: React.FC<{ showFooter: boolean }> = ({
  showFooter = true,
}) => {
  const t = useTranslate();

  const onPrivacySettingsClick = (e: MouseEvent) => {
    try {
      (window as any).Didomi?.notice?.show();
    } catch (e) {}

    e.preventDefault();
  };

  return (
    <footer
      className={clsx(styles.footer, {
        [styles.no_background]: !showFooter,
      })}
    >
      {showFooter && (
        <div>
          <div>
            <div className={clsx(styles.section, styles.menu)}>
              <div className={styles.header}>
                {/* <MemoLogoSmall /> */}
                <MemoLogo className={styles.logo} />
                <div className={styles.prm}>
                  <Link href={"https://prm.radioline.co/"} passHref>
                    <a target="_blank" className={styles.alt_button}>
                      {t("webstite_addOrManage")}
                    </a>
                  </Link>
                </div>
              </div>
              <div className={styles.stores}>
                <a
                  className={styles.store_button}
                  href="https://apps.apple.com/app/radioline-radio-et-podcasts/id945478998"
                  target="_blank"
                >
                  <img src="/App_Store.png" />
                </a>
                <a
                  className={styles.store_button}
                  href="https://appgallery.huawei.com/app/C102907973"
                  target="_blank"
                >
                  <img src="/app_gallery.png" />
                </a>
                <a
                  className={styles.store_button}
                  href="https://play.google.com/store/apps/details?id=com.radioline.android.radioline"
                  target="_blank"
                >
                  <img src="/Google_Play_Store.png" />
                </a>
              </div>
            </div>
          </div>

          <div className={styles.bottom}>
            <menu>
              <Link href={"/privacy-policy?back=true"} passHref>
                <a>{t("settings_action_privacyPolicy")}</a>
              </Link>
              <Link href={"#"} passHref>
                <a onClick={onPrivacySettingsClick}>{t("webstite_privacySettings")}</a>
              </Link>
              <Link href={"/terms-of-use?back=true"} passHref>
                <a>{t("settings_action_termsOfUse")}</a>
              </Link>
              <Link href={"https://business.radioline.co/"} passHref>
                <a target="_blank">{t("webstite_ourSolutions")}</a>
              </Link>
              <Link href={"/contact-us?back=true"} passHref>
                <a>{t("settings_contact")}</a>
              </Link>
            </menu>
          </div>
        </div>
      )}
    </footer>
  );
};
