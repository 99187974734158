import type { NextPage } from "next";
import { useRouter } from "next/dist/client/router";
import Head from "next/head";
import React, { useEffect } from "react";
import styles from "./index.module.scss";
import { useTranslate } from "app/hooks/lang";
import { useGoBackHistory } from "app/hooks/go-back.hook";
import { useCountryContext } from "app/context/country.context";
import MemoLoader from "app/components/icons/Loader";
import useMetaDescription from "app/utils/getMetaDescription";
import { useAppSelector } from "app/store/store";
import { selectUserState } from "app/store/slices/user";

const Home: NextPage = () => {
  const router = useRouter();
  const { country } = useCountryContext();
  const t = useTranslate();
  const { selectedDiscoverLanguage } = useAppSelector(selectUserState);
  const countryCode = selectedDiscoverLanguage?.permalink.split('/').pop() || country.countryCode;

  useEffect(() => {
    if (country) {
      router.replace(`/discover/${countryCode}?back=true`);
    }
  }, [router, country]);
  useGoBackHistory("DISCOVER");
  const getMetaDescription = useMetaDescription();

  return (
    <div className={styles.container}>
      <Head>
        <title>{t("navigation_discover")}</title>
        { getMetaDescription() }
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <div className="loader fullpage">
        <MemoLoader />
      </div>
    </div>
  );
};

export default Home;
