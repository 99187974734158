import { useFeaturesContext } from "app/context/features.context";

type VlybyContainerProps = {
  height?: string;
  width?: string;
  variant?: "floating" | "fullScreenPlayer";
};

const iFrameSrcs = {
  floating: "/vlyby/vlyby-player-floating.html",
  fullScreenPlayer: "/vlyby/vlyby-player.html",
};

export const VlybyContainer: React.FC<VlybyContainerProps> = ({
  height = "100%",
  width = "100%",
  variant = "fullScreenPlayer",
}) => {
  const { vlybyPublisherId, vlybyPublisherRef, isAdTestMode, isObigoMode } = useFeaturesContext();

  if (typeof window !== "undefined") {
    (window as any).vlybyId = vlybyPublisherId;
    (window as any).vlybyPubRef = vlybyPublisherRef;
    
    if (isAdTestMode && isObigoMode) {
      (window as any).vlybyOverridePlacement = "obigo";
    }
  }

  return <iframe src={iFrameSrcs[variant]} width={width} height={height} />;
};
